import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Layout } from "src/components/Layout"
import { Helmet } from "src/components/Helmet"
import { Container } from "src/components/Container"

const BlogTemplate = ({
  data: {
    blogPost: {
      title,
      bodyNode: {
        childMarkdownRemark: { html },
      },
    },
  },
}) => {
  return (
    <>
      <Helmet title={title}></Helmet>
      <Layout>
        <Container article>
          <Inner>
            <Heading>{title}</Heading>
            <Content dangerouslySetInnerHTML={{ __html: html }}></Content>
          </Inner>
        </Container>
      </Layout>
    </>
  )
}

export default BlogTemplate

export const query = graphql`
  query($id: String!) {
    blogPost: datoCmsBlog(id: { eq: $id }) {
      title
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

const Inner = styled.article`
  background-color: #fff;
  border-radius: 6px;
  padding: 32px;

  margin-bottom: 40px;
`

const Content = styled.div`
  line-height: 140%;

  img {
    display: block;
    height: auto;
    max-width: 100%;
    border-radius: 6px;
  }
`

const Heading = styled.h1`
  font-size: 7vmin;
  margin-top: 0;
  color: ${({ theme }) => theme.colors.contrast};
`
